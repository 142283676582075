import useWindowDimensions from '../lib/useWindowDimensions';
import { getSizeByWidth } from '../lib/size';

const useGetSize = () => {
  const { width } = useWindowDimensions();
  const size = getSizeByWidth(width);
  return size;
};

export default useGetSize;
