import { useState } from 'react';

import { createContainer } from 'react-tracked';

const initialState = {
  size: 'sm',
};

const useValue = () => useState(initialState);

export const { Provider, useTracked, useUpdate, useTrackedState } = createContainer(useValue);
