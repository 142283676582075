import React from 'react';
import { Provider as StateProvider, useUpdate } from '../../lib/state';
import useGetSize from '../../lib/useGetSize';

const Init = ({ children }) => {
  const size = useGetSize();
  const setState = useUpdate();
  setState(prev => ({ ...prev, size }));
  return <>{children}</>;
};

// called by gatsby-plugin-layout
const GatsbyPluginLayout = ({ children }) => (
  <StateProvider>
    <Init>{children}</Init>
  </StateProvider>
);

export default GatsbyPluginLayout;
