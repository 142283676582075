// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-access-jsx": () => import("./../../../src/pages/access.jsx" /* webpackChunkName: "component---src-pages-access-jsx" */),
  "component---src-pages-annoucements-jsx": () => import("./../../../src/pages/annoucements.jsx" /* webpackChunkName: "component---src-pages-annoucements-jsx" */),
  "component---src-pages-anti-body-campaign-jsx": () => import("./../../../src/pages/anti-body-campaign.jsx" /* webpackChunkName: "component---src-pages-anti-body-campaign-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cardio-jsx": () => import("./../../../src/pages/cardio.jsx" /* webpackChunkName: "component---src-pages-cardio-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-pcr-jsx": () => import("./../../../src/pages/contact-pcr.jsx" /* webpackChunkName: "component---src-pages-contact-pcr-jsx" */),
  "component---src-pages-contact-reservation-pcr-jsx": () => import("./../../../src/pages/contact-reservation-pcr.jsx" /* webpackChunkName: "component---src-pages-contact-reservation-pcr-jsx" */),
  "component---src-pages-covid-19-jsx": () => import("./../../../src/pages/covid19.jsx" /* webpackChunkName: "component---src-pages-covid-19-jsx" */),
  "component---src-pages-covid-article-list-01-jsx": () => import("./../../../src/pages/covid-article-list01.jsx" /* webpackChunkName: "component---src-pages-covid-article-list-01-jsx" */),
  "component---src-pages-covid-article-list-02-jsx": () => import("./../../../src/pages/covid-article-list02.jsx" /* webpackChunkName: "component---src-pages-covid-article-list-02-jsx" */),
  "component---src-pages-covid-pcr-cn-jsx": () => import("./../../../src/pages/covid-pcr-cn.jsx" /* webpackChunkName: "component---src-pages-covid-pcr-cn-jsx" */),
  "component---src-pages-covid-pcr-jsx": () => import("./../../../src/pages/covid-pcr.jsx" /* webpackChunkName: "component---src-pages-covid-pcr-jsx" */),
  "component---src-pages-departments-jsx": () => import("./../../../src/pages/departments.jsx" /* webpackChunkName: "component---src-pages-departments-jsx" */),
  "component---src-pages-dr-jsx": () => import("./../../../src/pages/dr.jsx" /* webpackChunkName: "component---src-pages-dr-jsx" */),
  "component---src-pages-endoscopy-jsx": () => import("./../../../src/pages/endoscopy.jsx" /* webpackChunkName: "component---src-pages-endoscopy-jsx" */),
  "component---src-pages-english-jsx": () => import("./../../../src/pages/english.jsx" /* webpackChunkName: "component---src-pages-english-jsx" */),
  "component---src-pages-exams-jsx": () => import("./../../../src/pages/exams.jsx" /* webpackChunkName: "component---src-pages-exams-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-internal-jsx": () => import("./../../../src/pages/internal.jsx" /* webpackChunkName: "component---src-pages-internal-jsx" */),
  "component---src-pages-kampo-jsx": () => import("./../../../src/pages/kampo.jsx" /* webpackChunkName: "component---src-pages-kampo-jsx" */),
  "component---src-pages-nagare-jsx": () => import("./../../../src/pages/nagare.jsx" /* webpackChunkName: "component---src-pages-nagare-jsx" */),
  "component---src-pages-pancreas-examination-jsx": () => import("./../../../src/pages/pancreas/examination.jsx" /* webpackChunkName: "component---src-pages-pancreas-examination-jsx" */),
  "component---src-pages-pancreas-index-jsx": () => import("./../../../src/pages/pancreas/index.jsx" /* webpackChunkName: "component---src-pages-pancreas-index-jsx" */),
  "component---src-pages-pancreas-mibyo-jsx": () => import("./../../../src/pages/pancreas/mibyo.jsx" /* webpackChunkName: "component---src-pages-pancreas-mibyo-jsx" */),
  "component---src-pages-pr-20230425-jsx": () => import("./../../../src/pages/pr-20230425.jsx" /* webpackChunkName: "component---src-pages-pr-20230425-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy_policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-qanda-jsx": () => import("./../../../src/pages/qanda.jsx" /* webpackChunkName: "component---src-pages-qanda-jsx" */),
  "component---src-pages-recruit-jsx": () => import("./../../../src/pages/recruit.jsx" /* webpackChunkName: "component---src-pages-recruit-jsx" */),
  "component---src-pages-reservation-jsx": () => import("./../../../src/pages/reservation.jsx" /* webpackChunkName: "component---src-pages-reservation-jsx" */),
  "component---src-pages-respiratory-jsx": () => import("./../../../src/pages/respiratory.jsx" /* webpackChunkName: "component---src-pages-respiratory-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-telemedicine-jsx": () => import("./../../../src/pages/telemedicine.jsx" /* webpackChunkName: "component---src-pages-telemedicine-jsx" */),
  "component---src-pages-vaccine-jsx": () => import("./../../../src/pages/vaccine.jsx" /* webpackChunkName: "component---src-pages-vaccine-jsx" */),
  "component---src-templates-announcement-template-jsx": () => import("./../../../src/templates/AnnouncementTemplate.jsx" /* webpackChunkName: "component---src-templates-announcement-template-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-covid-19-template-jsx": () => import("./../../../src/templates/Covid19Template.jsx" /* webpackChunkName: "component---src-templates-covid-19-template-jsx" */),
  "component---src-templates-dental-template-jsx": () => import("./../../../src/templates/DentalTemplate.jsx" /* webpackChunkName: "component---src-templates-dental-template-jsx" */),
  "component---src-templates-diabetes-template-jsx": () => import("./../../../src/templates/DiabetesTemplate.jsx" /* webpackChunkName: "component---src-templates-diabetes-template-jsx" */),
  "component---src-templates-kampo-template-jsx": () => import("./../../../src/templates/KampoTemplate.jsx" /* webpackChunkName: "component---src-templates-kampo-template-jsx" */),
  "component---src-templates-pancreas-template-jsx": () => import("./../../../src/templates/PancreasTemplate.jsx" /* webpackChunkName: "component---src-templates-pancreas-template-jsx" */),
  "component---src-templates-respiratory-template-jsx": () => import("./../../../src/templates/RespiratoryTemplate.jsx" /* webpackChunkName: "component---src-templates-respiratory-template-jsx" */)
}

